import { Route, Redirect, Switch } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";
import PrivateRoute from "../../routing/privateRoute";

import { ToastContainer } from "react-toastify";

const UserPages = lazy(() => import("../../DemoPages/UserPages"));
const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));
const AdminDashboards = lazy(() => import("../../DemoPages/AdminDashboard"));
const ClientFeatures = lazy(() => import("../../DemoPages/ClientFeatures"));
const CrowdSafetyAnalytics = lazy(() => import("../../DemoPages/Crowd-Safety"));
const AutomatedQueueManagement = lazy(() =>
  import("../../DemoPages/AutomatedQueueManagement")
);
const RetailAnalytics = lazy(() => import("../../DemoPages/RetailAnalytics"));
const Settings = lazy(() => import("../../DemoPages/Settings"));
const Configurations = lazy(() => import("../../DemoPages/Configurations"));
const SiteReports = lazy(() => import("../../DemoPages/SiteReports"));
const NotFound = lazy(() => import("../../DemoPages/UserPages/NotFound/"));
const AppMain = () => {
  return (
    <Fragment>
      {/* Pages */}
      <Suspense
        fallback={
          <div className="loader-container">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale-party" />
              </div>
            </div>
          </div>
        }
      >
        <Switch>
          <Route path="/pages" component={UserPages} />
          {/* Dashboards */}
          <PrivateRoute path="/dashboard" component={Dashboards} />
          {/* Special Client Features*/}
          <PrivateRoute path="/features" component={ClientFeatures} />
          {/* Site Reports Features*/}
          <PrivateRoute path="/site-reports" component={SiteReports} />
          {/*Admin Dashboards */}
          <PrivateRoute path="/admindashboard" component={AdminDashboards} />
          {/* Settings */}
          <PrivateRoute path="/settings" component={Settings} />
          {/* Configurations */}
          <PrivateRoute path="/configurations" component={Configurations} />
          {/* Crowd-Safety */}
          <PrivateRoute path="/crowd-safety" component={CrowdSafetyAnalytics} />
          {/* Automated-Queue */}
          <PrivateRoute
            path="/automated-queue"
            component={AutomatedQueueManagement}
          />
          {/* Retail-Analytics */}
          <PrivateRoute path="/retail-analytics" component={RetailAnalytics} />
          {/* First Page */}
          <Route exact path="/" render={() => <Redirect to="/pages/login" />} />
          {/* Not Found URL */}
          <Route component={NotFound} />
        </Switch>{" "}
      </Suspense>

      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
