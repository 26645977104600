export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
export const LOADING = "LOADING";
export const GET_PROFILE = "GET_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const SHOW_CLIENT = "SHOW_CLIENT";
export const CLEAR_SHOW_CLIENT = "CLEAR_SHOW_CLIENT";
export const GET_FOOTFALL_TODAY_DATA = "GET_FOOTFALL_TODAY_DATA";
export const GET_FOOTFALL_ALL_DATA = "GET_FOOTFALL_ALL_DATA";
export const FOOTFALL_ERROR = "FOOTFALL_ERROR";
export const CLEAR_FOOTFALL = "CLEAR_FOOTFALL";
// export const GET_FOOTFALL_COUNT="GET_FOOTFALL_COUNT";
export const GET_SOCIALDISTANCING_COUNT = "GET_SOCIALDISTANCING_COUNT";
export const SOCIALDISTANCING_ERROR = "SOCIALDISTANCING_ERROR";
export const CLEAR_SOCIALDISTANCING = "CLEAR_SOCIALDISTANCING";
export const GET_SOCIAL_TODAY_DATA = "GET_SOCIAL_TODAY_DATA";
export const GET_MASKDETECTION_TODAY_COUNT = "GET_MASKDETECTION_TODAY_COUNT";
export const MASKDETECTION_ERROR = "MASKDETECTION_ERROR";
export const CLEAR_MASKDETECTION = "CLEAR_MASKDETECTION";
export const MASKPUSHER = "MASKPUSHER";
export const FOOTFALLPUSHER = "FOOTFALLPUSHER";
export const SOCIALPUSHER = "SOCIALPUSHER";
export const MASTER_CLIENT_SELECTION = "MASTER_CLIENT_SELECTION";
export const PDF_DWELL_TIME_ANALYSIS = "PDF_DWELL_TIME_ANALYSIS";
export const DWELL_TIME_ERROR = "DWELL_TIME_ERROR";
export const CLEAR_DWELL_TIME = "CLEAR_DWELL_TIME";
export const SELECTED_CAMERA = "SELECTED_CAMERA";
export const REMOVE_SELECTED_CAMERA = "REMOVE_SELECTED_CAMERA";
export const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const ALERT_CONFIG = "ALERT_CONFIG";
export const ADD_CAMERA = "ADD_CAMERA";
export const DELETE_CAMERA = "DELETE_CAMERA";
export const EDIT_CAMERA = "EDIT_CAMERA";
export const ADD_DEVICE = "ADD_DEVICE";
export const EDIT_DEVICE = "EDIT_DEVICE";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const MapUserBranchIdWithBranchName = "MapUserBranchIdWithBranchName";
